import type {
	Post,
	PermutiveIabTerm,
	IabTermWithSimilarity,
} from '@/types/entities';

function sliceTerms(
	terms: IabTermWithSimilarity[] | undefined,
): IabTermWithSimilarity[] {
	return (terms?.[0]?.id ? terms : []).slice(0, 10);
}

export function getPostIabTermsId(post?: Partial<Post>): string[] {
	const termsSimilarity = post?.meta?.iab_terms_similarity;
	return sliceTerms(termsSimilarity).map((term) => String(term.id));
}

export function getIabTermsTextAndSim(
	post?: Partial<Post>,
): PermutiveIabTerm[] {
	const termsSimilarity = post?.meta?.iab_terms_similarity;
	return sliceTerms(termsSimilarity).map((term) => {
		return {
			relevance: term.sim,
			text: term.label,
		};
	});
}
